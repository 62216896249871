<!-- 时效设置弹窗 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'时效设置':'时效设置'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="150px">
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="平台接单提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="客服响应:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机接单:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="平台派单:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机接单至出发提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机出发至到达提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机到达至完成提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="司机到达至回访提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至司机接单提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至司机出发提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row :gutter="18">
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至司机到达提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :sm="12">
          <el-col :span="12">
            <el-form-item label="下单至完成提醒:" prop="nickname">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考核:" prop="nickname" label-width="90px">
              <el-input
                  style="width: 140px;"
                  clearable
                  :maxlength="20"
                  v-model="form.nickname"
                  placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import validate from 'ele-admin/packages/validate';
import regions from 'ele-admin/packages/regions';

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 省市区数据
      regions: regions,
      // 选中的省市区
      city: [],
      // 选中的省市
      provinceCity: [],
      // 选中的省
      province: [],
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        // 取值赋予城市组件
        this.city = this.data.city;
        // 清空密码输入框
        this.form.password = ''
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
        // 清空省市区控件
        this.city = [];
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          // 城市数据处理
          this.form = Object.assign({}, this.form, {
            city: this.city
          });
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped>
</style>
